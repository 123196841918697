@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    font-size: 11pt;
    font-family: 'Open Sans', sans-serif;
}

.box-container.padded {
    padding: 10px 0;
}

section {
    margin-bottom: 100px;
    position: relative;

    &>h1,
    &>h2,
    &>h3,
    &>h4,
    &>h5,
    &>h6 {
        text-align: center;
        font-weight: bolder;
        text-transform: capitalize;
    }

    p {
        text-align: center;
    }

    &>.box-container {

        &>h2,
        &>h3,
        &>h4,
        &>h5,
        &>h6 {
            text-align: center;
            font-weight: bolder;
            text-transform: capitalize;
        }

        p {
            text-align: center;
        }
    }
}

.show-color-on-hover {
    filter: saturate(0) brightness(2);
    transition: all 0.5s ease-in-out;

    &:hover {
        filter: none
    }
}


.ion-slides-nav {
    position: absolute;
    top: calc(50vh - 40px);
    left: 0;
    right: 0;

    .swiper-button-prev {
        left: -20px;
    }

    .swiper-button-next {
        right: -20px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-next::before,
    .swiper-button-prev::before {
        content: "<";
        background: var(--ion-color-light);
        font-family: monospace;
        position: absolute;
        height: 40px;
        width: 80px;
        border-radius: 50%;
        margin-left: -30px;
        margin-top: -3px;
        transform: scale(0.5, 1);
        text-align: center;
        font-size: 3em;
        line-height: 0.9em;
    }

    .swiper-button-next::before {
        content: ">";
        margin-left: -15px;
    }
}

.card {
    margin: 5px;
    box-shadow: 1px 1px 3px var(--ion-color-medium);

    .card-content {
        padding: 15px;
        text-align: left;

    }
}

.padded {
    padding: 25px;
}

.extra-padded {
    padding: 25px 50px;
}