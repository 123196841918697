$sm-width: 768px;
$md-width: 992px;
$lg-width: 1280px;
$xl-width: 2000px;

$xs-screen: "screen and (max-width: #{$sm-width - 1px})";
$sm-screen: "screen and (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px})";
$md-screen: "screen and (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px})";
$lg-screen: "screen and (min-width: #{$lg-width}) and (max-width: #{$xl-width - 1px})";
$xl-screen: "screen and (min-width: #{$xl-width})";

.box-container {
    width: 100%;
    margin: auto;
}

@media only #{$xs-screen} {
    .ion-slides-nav {

        .swiper-button-prev {
            left: 5px;
        }

        .swiper-button-next {
            right: 10px;
        }
    }
}

@media only #{$sm-screen} {
    .ion-slides-nav {

        .swiper-button-prev {
            left: 5px;
        }

        .swiper-button-next {
            right: 10px;
        }
    }
}

@media only #{$md-screen} {
    .box-container {
        width: #{$md-width};
    }
}

@media only #{$lg-screen} {
    .box-container {
        width: #{$lg-width};
    }
}

@media only #{$xl-screen} {
    .box-container {
        width: #{$xl-width};
    }
}